@import '../../stylesheets/dir';

.work-thumb {
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    max-width: 320px;
    height: 175px;
  }

  h3 {
    color: $other-blue-grey;
    margin: 1rem 0;
    font-size: 1.55rem;
  }

  .project-tags {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      display: inline-block;
      padding: 0 0.25rem;
      font-size: 0.85rem;
      color: $other-blue-grey;
    }
  }

  a {
    box-sizing: border-box;
    display: inline-block;
    margin: 1rem;
    padding: 0.75rem 1.25rem;
    background: $other-blue-grey;
    color: $white;
    text-decoration: none;
    width: 90px;
    transition: all 0.3s ease;
  }

  a:hover {
    background: $prim-blue;
  }
}

@media (max-width: 1024px) {
  .work-thumb {
    height: 100%;

    img {
      height: 175px;
    }

    h3 {
      color: $other-blue-grey;
      margin: 1rem 0;
      font-size: 1.45rem;
    }

    a {
    }
  }
}
