@import './stylesheets/dir';

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  letter-spacing: 0.8px;
}

.container {
  width: 70%;
  margin: auto;
  text-align: center;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Open Sans', sans-serif;
  text-align: center;
}

// Headings
h1 {
  font-size: 6rem;
}
h2 {
  font-size: 3rem;
}
h3 {
  font-size: 2rem;
}
h4 {
  font-size: 1.75rem;
}
h5 {
  font-size: 1.25rem;
}
h6 {
  font-size: 1rem;
}

.no-v-scroll {
  overflow-y: hidden;
}

// Footer
.social-links {
  a {
    padding: 1rem;
    display: inline-block;
    margin: 0 1rem;
    font-size: 2rem;
    border-radius: 100%;
    i {
      transition: 0.3s all ease;

      color: $prim-blue;
    }
  }

  a:hover {
    i {
      color: $prim-font-color;
    }
  }
}

// Media Queries
@media (max-width: 768px) {
  .container {
    width: 80%;
  }
}
@media (max-width: 425px) {
  .container {
    width: 90%;
  }
}
