$prim-blue: #2e9bff;
$prim-dark-blue: #2176ae;
$prim-orange: #fe6847;
$prim-yellow: #fbb13c;

// Other Colors
$white: #fff;
$black: #000;

// Contact Form
$cf-red-dark: rgb(235, 51, 73);
$cf-red-dark2: rgb(173, 46, 61);

$other-blue-grey: #343443;
$other-bright-blue: #0084ff;
$other-grey-blue: #ecedef;

// Font Colors
$prim-font-color: #141414;

// 051923 blue-black
// Charcoal 373F51
// ECEDEF
