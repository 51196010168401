@import '../../stylesheets/dir';

#my-work {
  height: 100%;
  display: flex;
  padding: 1.25rem 0;

  h2 {
    margin: 1.75rem 0 0 0;
  }

  .work-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
    margin: 1.75rem 0;
  }
}

@media (max-width: 1024px) {
  #my-work {
    height: 100%;
    .work-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
@media (max-width: 768px) {
  #my-work {
    height: 100%;
    .work-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
@media (max-width: 550px) {
  #my-work {
    height: 100%;
    .work-grid {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
