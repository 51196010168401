@import '../../stylesheets/dir';

#contact {
  padding: 3rem 0;
  // Gradient BG
  background: #eb3349; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #f45c43,
    #eb3349
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #f45c43,
    #eb3349
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  h2 {
    margin: 0 0 1rem 0;
    color: $white;
  }

  #contact-form {
    .form-group {
      padding: 1rem;
      background: rgba($cf-red-dark2, 0.5);
      width: 50%;
      margin: 0.75rem auto;
    }
    label,
    input,
    textarea {
      display: inline-block;
      width: 100%;
    }

    label {
      text-align: left;
      padding: 0 0 0.6rem 0;
      font-size: 1.25rem;
      color: $white;
    }

    input,
    textarea {
      box-sizing: border-box;
      font-size: 1rem;
      font-family: 'Roboto', sans-serif;
      padding: 0.6rem 0.5rem;
      color: $black;
      border: none;
      background: rgba($white, 0.9);
    }

    textarea {
      resize: vertical;
    }

    #submit {
      padding: 0.5rem;
      background: $prim-blue;
      border: none;
      cursor: pointer;
      display: inline-block;
      margin: 1rem 0;
      color: $white;
      font-size: 1.5rem;
      transition: all 0.3s ease;
      width: 50%;
    }

    #submit:hover {
      background: $prim-dark-blue;
    }
  }
}

@media (max-width: 1024px) {
  #contact {
    #contact-form {
      .form-group {
        width: 80%;
      }

      #submit {
        width: 80%;
      }
    }
  }
}
@media (max-width: 425px) {
  #contact {
    #contact-form {
      .form-group {
        width: 90%;
      }

      #submit {
        width: 90%;
      }
    }
  }
}
